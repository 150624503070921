import client from "@/api";
import store from "@/store";
import { GetActivePlan } from "@/api/plano";

// Caminhos
import { _rotaSetPagamento } from "@/api/_caminhosApi";

export function ativarSeteDias(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .post(`${_rotaSetPagamento()}`, data)
    .then(() => {
      store.commit("pagamento/SET_PAYMENT_SUCCESSS", true);
      GetActivePlan();
    })
    .catch(erro => {
      store.commit("interacoes/SET_LOADING", false);
      store.commit("pagamento/SET_PAYMENT_SUCCESSS", false);
      store.commit("interacoes/SET_MODAL", {
        ativado: true,
        mensagem: erro.response.data.message,
        tipo: "erro",
      });
    })
    .then(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
