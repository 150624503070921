export function addressErro() {
  let erro = "";
  const value = this.$v.address;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Por favor, digite o endereço completo";
  }
  return erro;
}
export function cardNumberErro() {
  let erro = "";
  const value = this.$v.form.cardNumber;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  const regex = /^3[47][0-9]{13}$|^(6541|6556)[0-9]{12}$|^389[0-9]{11}$|^3(?:0[0-5]|[68][0-9])[0-9]{11}$|^65[4-9][0-9]{13}|64[4-9][0-9]{13}|6011[0-9]{12}|(622(?:12[6-9]|1[3-9][0-9]|[2-8][0-9][0-9]|9[01][0-9]|92[0-5])[0-9]{10})$|^63[7-9][0-9]{13}$|^(?:2131|1800|35d{3})d{11}$|^9[0-9]{15}$|^(6304|6706|6709|6771)[0-9]{12,15}$|^(5018|5020|5038|6304|6759|6761|6763)[0-9]{8,15}$|^5[1-5][0-9]{14}$|^(6334|6767)[0-9]{12}|(6334|6767)[0-9]{14}|(6334|6767)[0-9]{15}$|^(4903|4905|4911|4936|6333|6759)[0-9]{12}|(4903|4905|4911|4936|6333|6759)[0-9]{14}|(4903|4905|4911|4936|6333|6759)[0-9]{15}|564182[0-9]{10}|564182[0-9]{12}|564182[0-9]{13}|633110[0-9]{10}|633110[0-9]{12}|633110[0-9]{13}$|^(62[0-9]{14,17})$|^4[0-9]{12}(?:[0-9]{3})?$|^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14})$/g;

  if (regex.test(value.$model)) {
    erro = "Cartão Inválido";
  }

  return erro;
}
export function documentErro() {
  let erro = "";
  const value = this.$v.form.document;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  const regex = /^(cpf|cnpj)$/;

  if (regex.test(value.$model)) {
    erro = "Documento inválido";
  }

  return erro;
}
export function nameErro() {
  let erro = "";
  const value = this.$v.form.name;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function validityErro() {
  let erro = "";
  const value = this.$v.form.validity;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function cvvErro() {
  let erro = "";
  const value = this.$v.form.cvv;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}

export function streetNumberErro() {
  let erro = "";
  const value = this.$v.form.streetNumber;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function neighborhoodErro() {
  let erro = "";
  const value = this.$v.form.neighborhood;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function postalCodeErro() {
  let erro = "";
  const value = this.$v.form.postalCode;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function cityErro() {
  let erro = "";
  const value = this.$v.form.city;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
export function stateErro() {
  let erro = "";
  const value = this.$v.form.state;
  if (!value.$dirty) {
    return erro;
  }
  if (!value.required) {
    erro = "Obrigatório";
  }
  return erro;
}
