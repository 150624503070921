<template>
  <div>
    <section class="plans-unpaid" v-if="planosUnpaid.length > 0 && !selectedPlan">
      <h2>Assinaturas pendentes de pagamento</h2>
      <table class="table">
        <thead>
          <tr>
            <th><b>Plano</b></th>
            <th><b>Valor</b></th>
            <th><b>Período</b></th>
            <th><b>Data</b></th>
            <th><b>Tipo</b></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in planosUnpaid"
            :key="index"
            >
            <td>{{item.plan_details.name}}</td>
            <td>{{ numberToReal(item.plan_details.price) }}</td>
            <td>{{ periodicityText(item.plan_details.periodicity) }}</td>
            <td>{{item.created_at | filterDataHora}}</td>
            <td>{{paymentTypeText(item.pagarme_subscription.payment_method)}}</td>
            <td>
              <a target="_blank" :href="item.pagarme_subscription.manage_url">Gerenciar</a>
            </td>
          </tr>
        </tbody>
      </table>
    </section>

    <section class="plans">
      <div v-if="!pagamentoSucesso">
        <div v-if="!selectedPlan">
          <h1 class="title" v-if="!trial.expired">
            Seja bem-vindo à vDash!
          </h1>
          <h1 class="title" v-else>
            <span>Ops.</span>
            Seu período expirou.
          </h1>

          <h2 class="subtitle" v-if="!trial.expired">Para prosseguirmos, que tal escolher o plano ideal para o seu negócio?</h2>
          <h2 class="subtitle" v-else>
            Para continuar utilizando os recursos do vDash, que tal escolher o plano ideal para o seu negócio?
          </h2>

          <p class="text">
            <!-- Escolha abaixo qual é a quantidade de pedidos que sua loja recebe por mês.
            <small>
              Tem mais de 15000 pedidos por mês?
              <a href="mailto:contato@vdash.com.br" target="_blank">clique aqui.</a>
            </small> -->
            <small>
              Precisa de uma solução customizada? 
              <a href="mailto:contato@vdash.com.br" target="_blank">clique aqui.</a>
            </small>
          </p>
          <!-- <DragProgress v-model="ranged" :min="0" :max="15000" :default="0" class="slider" /> -->
          <div class="card-list-conatiner position-relative d-block w-100">
            <div class="card-list">
              <label
                class="card-list__column selected-label"
                v-for="(plano, index) in planosSelecionados"
                :for="plano.id"
                :key="index"
                @change="onChange(plano, ranged)"
              >
                <input type="radio" name="selected-item" :id="plano.id" :value="plano.id" v-model="idPlan" />
                <div class="selected-content" :class="plano.periodicity == 90 && idPlan == 0 ? '--active' : ''">
                  <span class="card-list__recommend" v-if="plano.periodicity == 90">RECOMENDADO</span>
                  <span class="card-list__discont" v-if="plano.periodicity == 90">15% off</span>
                  <span class="card-list__discont --primary" v-if="plano.periodicity == 365">30% off</span>
                  <p class="card-list__title">
                    {{ periodicityText(plano.periodicity) }}
                    <small>*</small>
                  </p>
                  <p class="card-list__number">
                    <span class="card-list__number--sign">R$&nbsp;</span>
                    {{ plano.price_monthly.before() }}, <span class="card-list__number--zero">{{ plano.price_monthly.after() }}</span>
                  </p>
                  <p class="card-list__title--plan mt-none">{{ plano.name }}**</p>
                </div>
              </label>
            </div>
          </div>

          <div class="notice-list">
            <span
              v-for="(notice, index) in planosSelecionados"
              :key="index + 1"
              class="notice-list__notice"
              :class="notice.id == idPlan ? '--show' : ''"
            >
              * {{ numberToReal(notice.price) }} pago a cada {{ notice.periodicity }} dias.
              <br />
              <br />
              ** Informações: {{ notice.plan_rules }}
            </span>
          </div>

          <div class="card-list-conatiner card-list-bonus position-relative d-block w-100" v-if="planosBonus.length > 0">
            <h3>Planos especiais disponíveis</h3>
            <div class="card-list">
              <label
                class="card-list__column selected-label"
                v-for="(plano, index) in planosBonus"
                :for="plano.id"
                :key="index"
                @change="onChange(plano, ranged)"
              >
                <input type="radio" name="selected-item" :id="plano.id" :value="plano.id" v-model="idPlan" />
                <div class="selected-content" :class="plano.periodicity == 90 && idPlan == 0 ? '--active' : ''">
                  <!-- <span class="card-list__recommend" v-if="plano.periodicity == 90">RECOMENDADO</span> -->
                  <span class="card-list__discont" v-if="plano.periodicity == 90">15% off</span>
                  <span class="card-list__discont --primary" v-if="plano.periodicity == 365">30% off</span>
                  <p class="card-list__title">
                    {{ periodicityText(plano.periodicity) }}
                    <small>*</small>
                  </p>
                  <p class="card-list__number">
                    <span class="card-list__number--sign">R$&nbsp;</span>
                    {{ plano.price_monthly.before() }},
                    <span class="card-list__number--zero">{{ plano.price_monthly.after() }}</span>
                  </p>
                  <p class="card-list__title--plan mt-none">{{ plano.name }}**</p>
                </div>
              </label>
            </div>
          </div>

          <div class="button-box">
            <button @click="handleStep" class="btn step">
              próximo
            </button>
          </div>
        </div>

        <div v-else>
          <div class="button-box" :class="selectedPlan ? 'mt-none' : ''">
            <button @click="handleStep" class="btn link">
              <IconArrowLeft></IconArrowLeft>
              mudar plano
            </button>
          </div>
          <PaymentsForm :idPlan="idPlan"></PaymentsForm>
        </div>
      </div>

      <div class="plans__sucesso" v-else>
        <div class="text-center">
          <img class="plans__image" src="~@/assets/images/icones/icon-check-lg.svg" />
          <h1 class="title">Parabéns!</h1>
          <h2 class="subtitle">
            Solicitação realizada com sucesso!
            <br />
            Acompanhe seu e-mail para verificar mais detalhes da sua assinatura.
            <!-- Agora é só aproveitar todos os benefícios da sua conta. -->
          </h2>

          <div class="button-box --sucesso">
            <button @click="closeSucesss" class="btn save">
              INICIAR!
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import DragProgress from "@/components/drag-progress/DragProgress.vue";
import PaymentsForm from "@/components/payments-form/PaymentsForm.vue";
import IconArrowLeft from "@/components/icones/IconArrowLeft.vue";
import { GetPlansAvailable, GetPlans, GetPlansUnpaid } from "@/api/plano";
import { numberToReal } from "@/utils/money";
import { filterDataHora } from "@/utils/date";
import { VerifyTrial } from "@/api/plano";

export default {
  props: ["showModal"],
  components: {
    // DragProgress,
    PaymentsForm,
    IconArrowLeft,
  },
  data() {
    return {
      ranged: 5000,
      idPlan: null,
      groupPlanos: [],
      plansActives: [],
      selectedPlan: false,
    };
  },
  filters: {
    filterDataHora,
  },
  computed: {
    ...mapState({
      planosDisponiveis: state => state.plano.planosDisponiveis,
      planosUnpaid: state => state.plano.planosUnpaid,
      planosSelecionados: state => state.plano.planosSelecionados,
      planosBonus: state => state.plano.planosBonus,
      trial: state => state.plano.verifyTrial,
      pagamentoSucesso: state => state.pagamento.pagamentoSucesso,
    }),
  },
  mounted() {
    this.GetPlansAvailable();
    this.GetPlans();
    this.GetPlansUnpaid();
  },
  methods: {
    GetPlansAvailable,
    GetPlans,
    GetPlansUnpaid,
    numberToReal,
    VerifyTrial,
    handleStep() {
      this.selectedPlan = !this.selectedPlan;
    },
    closeSucesss() {
      this.$store.commit("pagamento/SET_PAYMENT_SUCCESSS", false);
      this.$store.commit("plano/SET_MODAL", false);
      this.VerifyTrial();
    },
    periodicityText(value, complement = "") {
      if (value == "30") {
        return "Mensal" + complement;
      }
      if (value == "90") {
        return "Trimestral" + complement;
      }
      if (value == "365") {
        return "Anual" + complement;
      }
    },
    paymentTypeText(value) {
      if (value == "credit_card") {
        return "Cartão de crédito";
      }
      if (value == "boleto") {
        return "Boleto";
      }
    },
    activePlan(planos, ranged) {
      // console.info('activePlan', planos, ranged);
      const filters = [
        {
          1000: {
            value: [0, 1000],
          },
          3500: {
            value: [1100, 3500],
          },
          5000: {
            value: [3600, 5000],
          },
          7500: {
            value: [5100, 7500],
          },
          15000: {
            value: [7600, 15000],
          }
        },
      ];
      if (planos != undefined)
        planos.forEach(element => {
          filters.every(val => {
            if (val[element.order_limit].value.includes(ranged)) {
              this.$store.commit("plano/SET_PLANOS_SELECIONADOS", planos);
              if (element.periodicity == 90) {
                this.idPlan = element.id;
              }
            }
          });
        });
    },
    onChange() {
      this.$store.state.plano.selected = this.idPlan;
    },
  },
  watch: {
    planosUnpaid(data) {
      console.log('planosUnpaid', data);
    },
    planosDisponiveis(data) {
      // console.log('planosDisponiveis', data)
      if (data) {
        // console.log('this.ranged', this.ranged)
        // agrupa o resultado dos planos pelo `order_limit`
        this.groupPlanos = data.reduce((r, a) => {
          if (a.bonus === 0) {
            r[a.order_limit] = [...(r[a.order_limit] || []), a];
          }
          return r;
        }, {});
        this.activePlan(this.groupPlanos[this.ranged], this.ranged);

        // Verificando os planos bonus
        var bonus = [];
        data.map(obj => {
          if (obj.bonus === 1) {
            bonus.push(obj);
          }
        });
        this.$store.commit("plano/SET_PLANOS_BONUS", bonus);
        return this.groupPlanos;
      }
    },
    ranged(ranged) {
      // const array = [1000, 2000, 3500, 5000, 7500, 9500, 12000, 15000];
      // const rule = [0, 1100, 2100, 3600, 5100, 7600, 9600, 12100];
      const array = [1000, 3500, 7500, 15000];
      const rule = [0, 1100, 3600, 7600];

      // seta a regra conforme o clique no ranged
      array.filter(function(item, index) {
        if (ranged >= rule[index] && ranged <= array[index]) {
          return (ranged = array[index]);
        }
      });

      // chama a funcao para setar o 3 planos ativos conforme o ranged escolhido
      this.activePlan(this.groupPlanos[ranged], ranged);
    },
  },
};
</script>

<style lang="scss">
.plans-unpaid {
  margin-bottom: 40px;
  h2 {
    color: $dark;
    font-weight: bold;
  }
}
.plans {
  display: flex;
  width: 100%;
  justify-content: center;
  max-width: 640px;
  > div {
    display: flex;
  }
  a {
    color: $gray;
    display: contents;
    text-decoration: underline;
    cursor: pointer;
    &:hover {
      color: #ff4876;
    }
  }
  &__sucesso {
    min-height: 600px;
    align-items: center;
  }
  &__logo-sucessso {
    max-width: 133px;
    text-align: center;
    margin: auto;
  }
  &__close {
    cursor: pointer;
    position: absolute;
    right: -40px;
    top: -40px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 200ms ease;
    &:hover {
      transform: scale(1.2);
    }
  }
  &__container {
    display: flex;
    flex-basis: 1;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    position: relative;
    @media screen and (max-width: 1290px) {
      flex-direction: column;
      align-items: center;
    }
  }
  &__list {
    margin-top: 20px;
    li {
      position: relative;
      display: flex;
      align-items: center;
      font-size: 17px;
      line-height: 1.3;
      color: $gray;
      font-weight: 400;
      margin-bottom: 15px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    svg {
      margin-right: 10px;
    }
  }
  &__image {
    margin-bottom: 35px;
  }
  &__credits {
    font-size: 11px;
    line-height: 1.3;
    font-weight: 400;
    color: #747474;
    display: inline-block;
  }
  &__credits {
    margin-top: 40px;
  }
  .title {
    font-size: 25px;
    font-weight: 600;
    margin-top: 0;
    white-space: nowrap;
    margin-bottom: 30px;
    span {
      color: #ff4876;
    }
  }
  .subtitle {
    font-size: 16px;
    margin-top: 15px;
    font-weight: 600;
    line-height: 1.6;
    color: $gray;
    &__logo {
      display: inline;
      color: #0a0202;
      span {
        color: #ff4876;
      }
    }
  }
  .text {
    font-size: 16px;
    small {
      display: inline;
      font-size: 14px;
    }
    a {
      display: inline;
      color: #1babdd;
    }
  }
  p {
    margin-top: 20px;
    font-size: 17px;
    line-height: 1.3;
    color: $gray;
    font-weight: 400;
    a {
      display: block;
      margin-top: 20px;
    }
  }
  .button-box {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 25px;
    &.--sucesso {
      max-width: 133px;
      justify-content: center;
      margin: 25px auto auto;
    }
    .btn {
      display: flex;
      align-items: center;
      justify-content: center;
      max-width: 133px;
      width: 100%;
      height: 51px;
      border-radius: 5px;
      border: none;
      margin: 0;
      font-size: 16px;
      font-weight: bold;
      font-family: $font__main;
      text-transform: uppercase;
      text-decoration: none;
      &:focus {
        color: #ffffff;
        border: none;
        box-shadow: none;
        outline: none;
      }
    }
    .step {
      max-width: 133px;
      background-color: $red;
      color: $white;
      margin: 7.5px;
      color: #ffffff;
    }
    .save {
      background-color: #73cb53;
      color: $white;
      color: #ffffff;
    }
    .link {
      background: transparent;
      color: $red;
      font-size: 12px;
      height: auto;
      margin-right: auto;
      padding: 0;
      justify-content: flex-start;
      margin-bottom: 15px;
      svg {
        position: relative;
        top: 1px;
        transition: all 0.4s ease;
        margin-right: 5px;
      }
      &:hover {
        color: $red;
        text-decoration: underline;
        svg {
          margin-right: 15px;
        }
      }
    }
  }
  .card-list-conatiner {
    min-height: 225px;
    &.card-list-bonus {
      padding-top: 30px;
      > h3 {
        color: #1f1f1f;
        font-weight: bold;
        text-align: center;
      }
      .card-list {
        padding-top: 20px;
        position: relative;
        .card-list__title {
          margin-top: 0;
        }
      }
    }
  }
  .notice-list {
    margin-top: 25px;
    display: inline-block;
    &__notice {
      font-size: 11px;
      line-height: 1.3;
      font-weight: 400;
      color: #747474;
      display: inline-block;
      margin-bottom: 0;
      text-align: left;
      margin-top: 0;
      width: 100%;
      display: none;
      &.--show {
        display: inline-block;
      }
    }
  }
  .card-list {
    text-align: center;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    position: relative;
    top: 0;
    width: 100%;
    .selected-content {
      width: 170px;
      height: 144px;
      margin-right: 22px;
      border-radius: 6px;
      position: absolute;
      padding: 10px;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      top: -1px;
      left: -1px;
      &.--active {
        border: 1px solid #ff4876;
        .card-list__title {
          color: #000000;
        }
        .card-list__number {
          color: #ff4876;
        }
        .card-list__title--plan,
        .card-list__title,
        .card-list__number {
          opacity: 1;
        }
      }
    }
    .selected-label input:checked ~ .selected-content {
      .card-list__title {
        color: #000000;
      }
      .card-list__number {
        color: #ff4876;
      }
      .card-list__title--plan,
      .card-list__title,
      .card-list__number {
        opacity: 1;
      }
    }
    &__column {
      width: 170px;
      height: 144px;
      margin-right: 22px;
      border-radius: 6px;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
      border: 1px solid transparent;
      background: #fff;
      display: flex;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      position: relative;
      margin-bottom: 25px;
      border: 1px solid transparent;
      &:last-child {
        margin-right: 0;
      }
    }
    &__title {
      margin-top: 25px;
      font-size: 17px;
      font-weight: bold;
      opacity: 0.3;
      &--plan {
        font-weight: bold;
        font-size: 12px;
        opacity: 0.3;
      }
    }
    &__number {
      margin-top: 0;
      font-size: 30px;
      font-weight: 600;
      opacity: 0.3;
      white-space: nowrap;
      &--sign {
        font-size: 19px;
        margin-left: 10px;
      }
      &--zero {
        font-size: 19px;
        position: relative;
        top: -8px;
        left: -10px;
      }
    }
    &__discont {
      width: 76px;
      height: 27px;
      border-radius: 10px 0px 0px 10px;
      background: #ffc400;
      color: $white;
      font-size: 14px;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;
      top: 10px;
      right: 0;
      font-weight: 600;
      &.--primary {
        background: #0022ff;
      }
    }
    &__recommend {
      width: 132px;
      height: 30px;
      border-radius: 7px;
      background: #73cb53;
      color: $white;
      font-size: 13px;
      font-weight: 600;
      align-items: center;
      justify-content: center;
      display: flex;
      text-transform: uppercase;
      position: absolute;
      top: -40px;
    }
  }
}
</style>
