<template>
  <div>
    <Breadcrumbs area="Configurações" :data="location" />
    <div class="content">
      <Panel :data="location" />
      <Activeplan />
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "@/components/breadcrumbs/Breadcrumbs.vue";
import Panel from "@/components/panel/Panel.vue";
import Activeplan from "@/components/active-plan/Activeplan.vue";

export default {
  components: {
    Breadcrumbs,
    Panel,
    Activeplan,
  },
  data() {
    return {
      breadcrumbs: [],
      panel: [],
      location: "",
    };
  },
  mounted() {
    this.fillData();
  },
  methods: {
    fillData() {
      this.location = "Plano";
    },
  },
};
</script>

<style lang="scss" scoped>
.setup {
  height: 100%;
  .page-container {
    margin: 30px 0;
  }
}
.content {
  display: flex;
  align-items: flex-start;
}
.box {
  margin-top: 0 !important;
}
</style>
