<template>
  <div>
    <div class="active-plan" v-if="planoData && !pagamentoSucesso" :class="profile && profile.company_data ? '' : 'mt-none'">
      <div class="title">
        <p>Plano atual</p>
      </div>
      <div class="plan">
        <div class="name">
          <p>{{ planoData.name }}</p>
        </div>
        <div class="value">
          <p>R$</p> <span><b>{{ planoData.price_monthly.before() }}<span>, {{ planoData.price_monthly.after() }}</span></b> / mês</span>
        </div>
        <small class="date">Data de ativação {{ date | filterDataHora }}</small>
        <a :href="plan_manage_url" target="_blank" class="plan__link" v-if="plan_manage_url !== null">
          Gerencie sua assinatura
        </a>
      </div>
      <div class="icon">
        <img
          v-if="plan_status === 'active'"
          src="~@/assets/images/icones/active.svg"
          title="Assinatura ativa"
          v-b-tooltip.hover.right
        />
        <img
          v-else
          src="~@/assets/images/icones/inactive.svg"
          title="Gerencie sua assinatura para verificar o problema"
          v-b-tooltip.hover.top
        />
      </div>
    </div>
    <div v-else>
      <div class="plans-inner" :class="profile && profile.company_data ? '' : 'mt-none'">
        <Plans />
      </div>
    </div>
    <br />
    <p v-if="planoData && !pagamentoSucesso">
      Para tratar sobre o seu plano, atualização ou cancelamento, mande um e-mail para
      <a href="mailto:contato@vdash.com.br">contato@vdash.com.br</a>
      .
    </p>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Plans from "@/components/plans/Plans.vue";
import { GetActivePlan } from "@/api/plano";
import { filterDataHora } from "@/utils/date";
export default {
  components: {
    Plans,
  },
  filters: {
    filterDataHora,
  },
  data() {
    return {
      planoData: null,
      date: "",
      plan_manage_url: null,
      plan_status: null,
    };
  },
  computed: {
    ...mapState({
      planoAtivo: state => state.plano.planoAtivo,
      pagamentoSucesso: state => state.pagamento.pagamentoSucesso,
      profile: state => state.user.dadosCompletos,
    }),
  },
  mounted() {
    this.GetActivePlan();
  },
  methods: {
    GetActivePlan,
  },
  watch: {
    planoAtivo(data) {
      this.planoData = data.plan_details;
      this.plan_manage_url = data.pagarme_subscription ? data.pagarme_subscription.manage_url : null;
      this.date = data.purchase_date;

      let status = ["trialing", "paid", "pending_payment", "active"];
      this.plan_status = status.includes(data.status) ? "active" : "inactive";
    },
  },
};
</script>

<style lang="scss" scoped>
.add-button {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 335px;
  width: 100%;
  height: 51px;
  border-radius: 5px;
  background-color: #41a7ff;
  cursor: pointer;

  a {
    border: none;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    font-family: $font__main;
    &:hover {
      color: #ffffff;
    }
  }
}

.title {
  margin-bottom: 20px;
  p {
    font-size: 22px;
    font-family: $font__main;
    font-weight: 600;
    color: #2c2c2c;
  }
}

.subtitle {
  margin-bottom: 36px;
  p {
    font-size: 16px;
    font-family: $font__main;
    font-weight: 600;
    color: #747474 !important;
  }
}

.active-plan {
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 438px;
  width: 100%;
  border: 3px solid #e6e6e6;
  border-radius: 10px;
  padding: 48px 52px;
  margin-right: 45px;
  margin-top: 65px;
  .title {
    margin-bottom: 36px;

    p {
      font-size: 18px;
      font-family: $font__main;
      font-weight: 600;
      color: #2c2c2c;
    }
  }
  .plan {
    &__link {
      color: #fd3064;
      display: inline-block;
      font-size: 13px;
      padding: 15px 0 0;
    }

    .name {
      margin-bottom: 12px;

      p {
        font-size: 31px;
        font-family: $font__main;
        font-weight: 600;
        color: #fd3064;
      }
    }

    .value {
      p {
        font-size: 16px;
        font-family: $font__main;
        font-weight: 600;
        color: #2c2c2c;
      }

      span {
        font-size: 24px;
        font-family: $font__main;
        font-weight: 600;
        color: #2c2c2c;
      }

      span b {
        font-size: 108px;
        font-family: $font__main;
        font-weight: 600;
        color: #2c2c2c;
        line-height: 108px;
      }
    }
    .date {
      font-size: 12px;
      margin-top: 15px;
      color: #747474;
      display: inline-block;
    }
  }

  .icon {
    position: absolute;
    top: 17px;
    right: 17px;
  }
}

.plans-inner {
  position: relative;
  display: flex;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 18px;
  margin-top: 65px;
  .plans {
    display: flex;
  }
}

@media only screen and (max-width: 1725px) {
  .active-plan {
    max-width: 360px;
    padding: 36px 39px;

    .plan {
      .name {
        p {
          font-size: 28px;
        }
      }

      .value {
        span b {
          font-size: 75px;
          line-height: 75px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1550px) {
  .active-plan {
    max-width: 320px;
    margin-right: 20px;
    padding: 24px 26px;

    .plan {
      .name {
        p {
          font-size: 20px;
        }
      }

      .value {
        p {
          font-size: 14px;
        }

        span {
          font-size: 22px;
        }

        span b {
          font-size: 50px;
          line-height: 50px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1300px) {
  .active-plan {
    .title {
      p {
        font-size: 14px;
      }
    }

    .plan {
      .name {
        p {
          font-size: 13px;
        }
      }

      .value {
        p {
          font-size: 14px;
        }

        span {
          font-size: 14px;
        }

        span b {
          font-size: 35px;
          line-height: 35px;
        }
      }
    }

    .icon {
      img {
        width: 20px;
      }
    }
  }
}
</style>
