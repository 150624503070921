<template>
  <div class="paymentform">
    <h2 class="paymentform__form-title --mt-lg">Endereço</h2>
    <div class="box">
      <form>
        <div class="field">
          <inputValidate :erro="addressErro">
            <!-- <label for="endereco">Endereço</label> -->
            <div class="dropdown">
              <input
                id="endereco"
                v-model="address"
                type="search"
                ref="address"
                placeholder="Digite o endereço completo"
                @blur="$v.address.$touch()"
                @input="clearSearch"
                v-bind:class="$v.address.$invalid && $v.address.$dirty ? 'has-error' : ''"
              />

              <div class="dropdown-container" ref="search" v-show="showDropdown == true && searchResults.length > 0">
                <span class="dropdown-item dropdown-item-loading" v-if="loading">Carregando...</span>
                <div v-else>
                  <div
                    class="dropdown-item"
                    v-for="(result, i) in searchResults"
                    :key="'prediction-' + i"
                    @click="setPlaceDetails(result)"
                  >
                    {{ result.description }}
                  </div>
                </div>
              </div>
            </div>
          </inputValidate>
          <div v-if="addressFill">
            <div class="inputs-column">
              <div class="field">
                <inputValidate :erro="streetNumberErro">
                  <label for="streetNumber">Numero</label>
                  <input
                    id="streetNumber"
                    ref="streetNumber"
                    type="tel"
                    name="streetNumber"
                    v-bind:class="$v.form.streetNumber.$invalid && $v.form.streetNumber.$dirty ? 'has-error' : ''"
                    v-model.trim="form.streetNumber"
                    @blur="$v.form.streetNumber.$touch()"
                  />
                </inputValidate>
              </div>
              <div class="field">
                <inputValidate :erro="neighborhoodErro">
                  <label for="neighborhood">Bairro</label>
                  <input
                    id="neighborhood"
                    type="text"
                    ref="neighborhood"
                    name="neighborhood"
                    v-bind:class="$v.form.neighborhood.$invalid && $v.form.neighborhood.$dirty ? 'has-error' : ''"
                    v-model.trim="form.neighborhood"
                    @blur="$v.form.neighborhood.$touch()"
                  />
                </inputValidate>
              </div>
              <div class="field">
                <inputValidate :erro="postalCodeErro">
                  <label for="postalCode">CEP</label>
                  <input
                    id="validade"
                    type="tel"
                    name="postalCode"
                    ref="postalCode"
                    v-mask="'99999-999'"
                    v-bind:class="$v.form.postalCode.$invalid && $v.form.postalCode.$dirty ? 'has-error' : ''"
                    v-model.trim="form.postalCode"
                    @blur="$v.form.postalCode.$touch()"
                  />
                </inputValidate>
              </div>
            </div>
            <div class="inputs-column">
              <div class="field">
                <inputValidate :erro="cityErro">
                  <label for="city">Cidade</label>
                  <input
                    id="city"
                    type="tel"
                    name="city"
                    ref="city"
                    v-bind:class="$v.form.city.$invalid && $v.form.city.$dirty ? 'has-error' : ''"
                    v-model.trim="form.city"
                    @blur="$v.form.city.$touch()"
                  />
                </inputValidate>
              </div>
              <div class="field">
                <inputValidate :erro="stateErro">
                  <label for="state">Estado</label>
                  <input
                    id="state"
                    type="tel"
                    name="state"
                    ref="state"
                    v-bind:class="$v.form.state.$invalid && $v.form.state.$dirty ? 'has-error' : ''"
                    v-model.trim="form.state"
                    @blur="$v.form.state.$touch()"
                  />
                </inputValidate>
              </div>
            </div>
          </div>
        </div>
          
        <h2 class="paymentform__form-title --mt-lg">Documento</h2>
        <div class="field">
          <inputValidate :erro="documentErro">
            <label for="telefone">CPF/CNPJ</label>
            <input
              type="tel"
              name="document"
              v-mask="documentMask"
              v-bind:class="$v.form.document.$invalid && $v.form.document.$dirty ? 'has-error' : ''"
              v-model.trim="form.document"
              @blur="$v.form.document.$touch()"
            />
          </inputValidate>
        </div>

        <h2 class="paymentform__form-title --mt-lg">Pagamento</h2>
        <div class="payment-options">
          <div v-bind:class="form.payment_type === 'credit_card' ? 'btn btn-primary btn-payment active' : 'btn btn-primary btn-payment'" @click="changePaymentType('credit_card')">
            <img src="~@/assets/images/icones/card.svg" alt="Boleto"> Cartão de crédito
          </div>
          <div v-bind:class="form.payment_type === 'boleto' ? 'btn btn-primary btn-payment active' : 'btn btn-primary btn-payment'" @click="changePaymentType('boleto')">
            <img src="~@/assets/images/icones/boleto.svg" alt="Boleto"> Boleto
          </div> 
        </div>

        <div v-if="form.payment_type === 'credit_card'">
          <h2 class="paymentform__form-title --mt-lg">Dados do cartão</h2>

          <div class="field">
            <inputValidate :erro="cardNumberErro">
              <label for="telefone">Número do Cartão</label>
              <input
                type="tel"
                name="cardNumber"
                v-mask="'9999 9999 9999 9999'"
                placeholder="____ ____ ____ ____"
                v-bind:class="$v.form.cardNumber.$invalid && $v.form.cardNumber.$dirty ? 'has-error' : ''"
                v-model.trim="form.cardNumber"
                @blur="$v.form.cardNumber.$touch()"
              />
            </inputValidate>
          </div>
          <div class="inputs-column">
            <div class="field">
              <inputValidate :erro="nameErro">
                <label for="name">Nome</label>
                <input
                  id="name"
                  type="text"
                  name="name"
                  v-bind:class="$v.form.name.$invalid && $v.form.name.$dirty ? 'has-error' : ''"
                  v-model.trim="form.name"
                  @blur="$v.form.name.$touch()"
                />
              </inputValidate>
            </div>
            <div class="field">
              <inputValidate :erro="validityErro">
                <label for="validade">Validade</label>
                <input
                  id="validade"
                  type="tel"
                  placeholder="MM/AA"
                  v-mask="'99/99'"
                  name="validade"
                  v-bind:class="$v.form.validity.$invalid && $v.form.validity.$dirty ? 'has-error' : ''"
                  v-model.trim="form.validity"
                  @blur="$v.form.validity.$touch()"
                />
              </inputValidate>
            </div>
            <div class="field">
              <inputValidate :erro="cvvErro">
                <label for="cvv" title="Código de Segurança atrás do cartão" v-b-tooltip.hover.top>CVV</label>
                <input
                  id="cvv"
                  type="tel"
                  v-mask="'999'"
                  name="cvv"
                  v-bind:class="$v.form.cvv.$invalid && $v.form.cvv.$dirty ? 'has-error' : ''"
                  v-model.trim="form.cvv"
                  @blur="$v.form.cvv.$touch()"
                />
              </inputValidate>
            </div>
          </div>
        </div>

        <div v-if="form.payment_type === 'boleto'">
          <h2 class="paymentform__form-title --mt-lg">Você receberá um e-mail com o boleto.</h2>
        </div>

        <div class="button-box" v-if="form.payment_type !== null">
          <LogoPagarme></LogoPagarme>
          <button class="save" type="button" @click="handleSave">CONTRATAR</button>
        </div>

      </form>
    </div>
  </div>
</template>

<script>
import LogoPagarme from "@/components/logos/LogoPagarme.vue";
import { required, requiredIf } from "vuelidate/lib/validators";
import * as vuelidate from "@/plugins/vuelidate/payment";
import { ativarSeteDias } from "@/api/pagamento";
import inputValidate from "@/components/formulario/InputValidate.vue";
import AwesomeMask from "awesome-mask";
export default {
  props: ["idPlan"],
  directives: {
    mask: AwesomeMask,
  },
  components: {
    inputValidate,
    LogoPagarme,
  },
  metaInfo() {
    return {
      script: [
        {
          src: `https://maps.googleapis.com/maps/api/js?key=AIzaSyCIMPMZiUfiMJ4evonW2V3n9in8rbBAIaQ&libraries=places`,
          async: true,
          defer: true,
          callback: () => this.initMap(),
        },
      ],
    };
  },
  data() {
    return {
      searchResults: [],
      autocomplete: null,
      loading: false,
      showDropdown: true,
      googlePlaces: "",
      address: null,
      addressFill: false,
      documentMask: "999.999.999-99",
      form: {
        payment_type: null,
        validity: "",
        document: "",
        cardNumber: "",
        name: "",
        streetNumber: "",
        neighborhood: "",
        postalCode: "",
        city: "",
        state: "",
      },
    };
  },
  validations: {
    address: {
      required,
    },
    form: {
      document: {
        required,
      },
      cardNumber: {
        required: requiredIf(function() {
          return this.form.payment_type === 'credit_card';
        }),
      },
      name: {
        required: requiredIf(function() {
          return this.form.payment_type === 'credit_card';
        }),
      },
      validity: {
        required: requiredIf(function() {
          return this.form.payment_type === 'credit_card';
        }),
      },
      cvv: {
        required: requiredIf(function() {
          return this.form.payment_type === 'credit_card';
        }),
      },
      streetNumber: {
        required,
      },
      neighborhood: {
        required,
      },
      postalCode: {
        required,
      },
      city: {
        required,
      },
      state: {
        required,
      },
    },
  },
  computed: {
    addressErro: vuelidate.addressErro,
    cardNumberErro: vuelidate.cardNumberErro,
    documentErro: vuelidate.documentErro,
    nameErro: vuelidate.nameErro,
    validityErro: vuelidate.validityErro,
    cvvErro: vuelidate.cvvErro,
    streetNumberErro: vuelidate.streetNumberErro,
    neighborhoodErro: vuelidate.neighborhoodErro,
    postalCodeErro: vuelidate.postalCodeErro,
    cityErro: vuelidate.cityErro,
    stateErro: vuelidate.stateErro,
  },
  mounted() {
    this.$refs.address.addEventListener("input", e => {
      if (e.currentTarget.value == "") this.setEmpty();
    });
  },
  methods: {
    ativarSeteDias,
    clearSearch() {
      this.showDropdown = true;
    },
    setEmpty() {
      let self = this;
      Object.keys(this.form).forEach(key => {
        self.form[key] = "";
        this.showDropdown = false;
        this.addressFill = false;
        this.$v.$reset();
      });
    },
    setFocus(ref) {
      this.$nextTick(function() {
        this.$refs[ref].focus();
      });
    },
    initMap() {
      this.autocomplete = new window.google.maps.places.AutocompleteService();
      let map = new window.google.maps.Map(document.createElement("div"));
      this.googlePlaces = new window.google.maps.places.PlacesService(map);
    },
    displaySuggestions(predictions, status) {
      if (status === window.google.maps.places.PlacesServiceStatus.OK && predictions) {
        this.searchResults = predictions;
        this.loading = false;
      } else {
        this.loading = true;
      }
    },

    setPlaceDetails(selected) {
      const brokenDownAddress = {};
      const map = {
        political: "state",
        postal_code: "postalCode",
        country: "country",
        street_number: "streetNumber",
        route: "streetName",
        sublocality_level_1: "neighborhood",
        administrative_area_level_2: "city",
        locality: "city1",
        administrative_area_level_1: "state",
      };
      this.googlePlaces.getDetails({ placeId: selected.place_id }, (place, status) => {
        if (status === window.google.maps.places.PlacesServiceStatus.OK) {
          this.addressFill = true;
          this.showDropdown = false;
          place.address_components.forEach(component => {
            brokenDownAddress[map[component.types[0]]] = component.short_name;
          });
          this.address = brokenDownAddress.streetName;
          this.form.streetNumber = brokenDownAddress.streetNumber;
          this.form.neighborhood = brokenDownAddress.neighborhood;
          this.form.postalCode = brokenDownAddress.postalCode;
          this.form.city = brokenDownAddress.city;
          this.form.state = brokenDownAddress.state;

          if (this.form.streetNumber == undefined) {
            this.setFocus("streetNumber");
          } else if (this.form.postalCode == undefined) {
            this.setFocus("postalCode");
          }
        }
      });
    },
    handleSave() {
      this.$v.form.$touch();
      if (this.$v.form.$pending || this.$v.form.$error) return;

      let card = this.form.cardNumber.replace(/[^\d]+/g, "");
      let validity = this.form.validity.replace(/[^\d]+/g, "");
      let postal = this.form.postalCode.replace(/[^\d]+/g, "");

      const data = {
        plan_id: this.idPlan,
        type: this.form.payment_type,
        document: this.form.document.replace(/[^\w\s]/gi, ""),
        address: {
          street: this.address,
          number: this.form.streetNumber,
          neighborhood: this.form.neighborhood,
          postal_code: postal,
          city: this.form.city,
          state: this.form.state,
        },
      };
      if (data.type === 'credit_card') {
        data.payment = {
          card: {
            name: this.form.name,
            number: card,
            cvv: this.form.cvv,
            expiration_date: validity,
          },
        };
      }
      this.ativarSeteDias(data);
    },
    changePaymentType(type) {
      this.form.payment_type = type;
    }
  },
  watch: {
    address(data) {
      this.initMap();
      const options = {
        input: data,
        componentRestrictions: { country: "br" },
      };
      this.autocomplete.getPlacePredictions(options, this.displaySuggestions);
    },
    "form.document"(data) {
      let value = data.replace(/[^\w\s]/gi, "");
      if (value.length == 14) {
        this.documentMask = "99.999.999/9999-99";
      } else {
        this.documentMask = "999.999.999-99999";
      }
    },
  },
};
</script>

<style lang="scss">
.paymentform {
  width: 100%;
  height: 100%;
  .panel__title {
    margin-bottom: 30px;
    margin-top: 50px;
    font-size: 22px;
    b {
      font-size: 45px;
      font-weight: 500;
    }
  }
  &__form-title {
    font-weight: 500;
    font-size: 22px;
    text-align: left;
    color: #2c2c2c;
    margin-bottom: 10px;
    &.--mt-lg {
      margin-top: 30px;
      margin-bottom: 20px;
    }
  }
  .box {
    .payment-options {
      display: flex;
      width: 100%;
      .btn-payment {
        border: none;
        align-items: center;
        background: #C1C7D1;
        color: $dark;
        display: flex;
        height: 48px;
        margin-right: 15px;
        justify-content: center;
        width: 230px;
        &:hover {
          background: darken(#C1C7D1, 15%);
        }
        &.active {
          background: $red;
          color: #fff;
          &:hover {
            background: $red;
          }
          img {
            filter: invert(1);
          }
        }
        img {
          margin-right: 15px;
        }
      }
    }
    form {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 0;

      .inputs-column {
        .field {
          margin-right: 20px;
          input {
            width: 100%;
          }
          input[id="name"] {
            width: 350px;
          }
          &:last-child {
            margin-right: 0;
          }
        }
      }
      .field {
        width: 100%;
        label {
          font-size: 16px;
          font-weight: 500;
          margin-bottom: 10px;
          display: block;
          color: $gray;
        }

        input {
          width: 100%;
          height: 49px;
          border-radius: 6px;
        }
        .input-erro-container {
          .has-error {
            border-color: #f83505;
          }
          .input-erro-mensagem {
            color: #f83505;
            font-size: 12px;
            margin-top: 0;
            position: relative;
            bottom: inherit;
            top: -15px;
            font-weight: 500;
          }
        }
      }
    }

    .button-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      svg {
        height: 52px;
        position: relative;
        left: -10px;
      }
      .save {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: 260px;
        width: 100%;
        height: 51px;
        border-radius: 5px;
        border: none;
        background-color: #73cb53;
        margin: 0;
        color: #ffffff;
        font-size: 16px;
        font-weight: bold;
        font-family: $font__main;
        text-transform: uppercase;
        &:focus {
          border: none;
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
